<template>
  <component
    :is="componentTag"
    v-bind="linkAttr"
    :outlined
    :rounded
    :is-active="isActive"
    :class="hadsButtonClassesArray"
    v-on="componentAttachedEvents"
  >
    <HaIcon
      v-if="iconStart"
      :icon="iconStart"
    />
    <slot />
    <HaIcon
      v-if="iconEnd"
      :icon="iconEnd"
    />
  </component>
</template>

<script setup lang="ts">
import { HaIcon } from '@ha/components-v3'
import { computed } from 'vue'
import { RouterLink } from 'vue-router'

const props = withDefaults(
  defineProps<{
    to?: string
    href?: string
    is?: string
    disableDefaultStyle?: boolean
    iconStart?: object
    iconEnd?: object
    isActive?: boolean
    rounded?: boolean
    link?: boolean
    outlined?: boolean
  }>(),
  {
    to: undefined,
    href: undefined,
    is: undefined,
    disableDefaultStyle: false,
    iconStart: undefined,
    iconEnd: undefined,
    isActive: false,
    rounded: false,
    outline: false
  }
)

const componentAttachedEvents = computed((): object => {
  const attachedEvents = {}

  if (componentTag.value !== 'a') {
    Object.assign(attachedEvents, {
      click: handleClick
    })
  }

  return attachedEvents
})

const hadsButtonClassesArray = computed(() => {
  const classesArray = ['hads-button']

  if (!props.disableDefaultStyle) {
    classesArray.push('hads-button-default-style')
  }

  return classesArray
})

const linkAttr = computed(() => {
  const attrs: {
    to?: string
    href?: string
  } = {}

  if (props.to) {
    attrs.to = props.to
  } else if (props.href) {
    attrs.href = props.href
  }

  return attrs
})

const componentTag = computed(() => {
  if (props.is) {
    return props.is
  } else if (props.to) {
    return RouterLink
  } else if (props.href) {
    return 'a'
  }

  return 'button'
})

const emit = defineEmits(['click'])

const handleClick = (e: Event) => {
  emit('click', e)
}
</script>

<style scoped lang="scss">
.hads-button {
  cursor: pointer;
  font-size: 0.875rem;
}

.hads-button-default-style {
  // local tokens
  --hads-button-background: var(--hads-theme-primary-background);
  --hads-button-color: var(--hads-color-air);
  --hads-button-padding: var(--hads-spacing-250)
    var(--hads-spacing-300);
  --hads-button-radius: var(--hads-radius-200);
  --hads-button-outline: var(--hads-outline);

  // base style
  display: inline-flex;
  gap: var(--hads-spacing-200);
  align-items: center;
  padding: var(--hads-button-padding);
  color: var(--hads-button-color);
  background: var(--hads-button-background);
  border: 1px solid currentcolor;
  border-radius: var(--hads-button-radius);
  outline: var(--hads-button-outline);
  outline-offset: var(--hads-outline-offset);
  transition: var(--hads-transition-simple);
  font-weight: 700;

  // states
  &:hover,
  &:focus-within {
    outline-color: var(
      --hads-theme-outline-color-hover
    ); // sugg. link with color primary
  }

  &:active,
  &[is-active='true'] {
    background: var(--hads-button-color);
    color: var(--hads-button-background);
  }

  // variant - rounded
  &[rounded='true'] {
    border-radius: var(--hads-radius-900);
  }

  // variant - outlined
  &[outlined='true'] {
    background: var(--hads-button-color);
    color: var(--hads-button-background);

    // states variant
    &:active,
    &[is-active='true'] {
      background: var(--hads-button-background);
      color: var(--hads-button-color);
    }
  }
}
</style>
